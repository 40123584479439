import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfig{
	constructor(private http: HttpClient){}

	private API_HOST_ENV:string = '';
	private APP_HOST_CREATECARDTOKEN_ENV:string = '';

	public getConfig(){
		return new Promise((resolve,reject)=>{
			this.http.get('../../../assets/config/api-config.json')
			.subscribe((response:any)=>{
				
				this.API_HOST_ENV = response.API_HOST_LOCAL;
				this.APP_HOST_CREATECARDTOKEN_ENV = response.API_HOST_LOCAL_RESTAPI;
				if(environment){
					console.log("Enviroment:", environment);
					if(environment.production){
						this.API_HOST_ENV = response.API_HOST_PRODUCTION; 
						this.APP_HOST_CREATECARDTOKEN_ENV = response.API_HOST_PRODUCTION_RESTAPI;
					}else if(environment.staging){
						this.API_HOST_ENV = response.API_HOST_STAGING;
						this.APP_HOST_CREATECARDTOKEN_ENV = response.API_HOST_STAGING_RESTAPI;
					}else if(environment.hmr){
						this.API_HOST_ENV = response.API_HOST_TEST_SQL_STAGING_PRODUCTION; //For testportal
						this.APP_HOST_CREATECARDTOKEN_ENV = response.API_HOST_TEST_SQL_STAGING_PRODUCTION_RESTAPI;
					}else if(environment.netevia){
						this.API_HOST_ENV = response.API_HOST_NETEVIA; //For NETEVIA
						this.APP_HOST_CREATECARDTOKEN_ENV = response.API_HOST_NETEVIA_RESTAPI;
					}
				}
				console.log("APP_HOST_CREATECARDTOKEN:", this.APP_HOST_CREATECARDTOKEN_ENV);
				return resolve(response);
			})
		})
	}

	get API_HOST():string{
		return this.API_HOST_ENV;
	}

	get APP_HOST_CREATECARDTOKEN():string{
		return this.APP_HOST_CREATECARDTOKEN_ENV;
	}
}