import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WebappConfig{
	constructor(private http:HttpClient){}

	private WEB_APP_HOST_ENV:string = '';
	public getConfig(){
		return new Promise((resolve,reject)=>{
			this.http.get('../../../assets/config/web-app-config.json')
			.subscribe((response:any)=>{
				
				this.WEB_APP_HOST_ENV = response.WEB_APP_LOCAL;

				if(environment){
					console.log("Enviroment:", environment);
					if(environment.production){
						this.WEB_APP_HOST_ENV = response.WEB_APP_PRODUCTION;
					}else if(environment.staging){
						this.WEB_APP_HOST_ENV = response.WEB_APP_STAGING;
					}else if(environment.hmr){
						this.WEB_APP_HOST_ENV = response.WEB_APP_TEST_SQL_STAGING_PRODUCTION;
					}else if(environment.netevia){
						this.WEB_APP_HOST_ENV = response.WEB_APP_NETEVIA;
					}
					
				}
				console.log("WEB_APP_HOST:", this.WEB_APP_HOST_ENV);
				return resolve(response);
			},error=>{
				console.error('webapp-config fail',error);
				return reject(error);
			})
		})
	}

	get WEB_APP_HOST():string{
		return this.WEB_APP_HOST_ENV;
	}
}
