import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
	},
	{
		path: 'CreateCardToken',
		loadChildren: () => import('./createcardtoken/createcardtoken.module').then(m => m.CreateCardTokenModule)
	},
	{
		path: 'VPayment',
		loadChildren: () => import('./createcardtoken/createcardtoken.module').then(m => m.CreateCardTokenModule)
	},
	{
		path: 'payment-response/:token',
		loadChildren: () => import('./payment-response/payment-response.module').then(m => m.PaymentResponseModule)
	},
	{
		path: 'payment-response/:token/:homeUrl',
		loadChildren: () => import('./payment-response/payment-response.module').then(m => m.PaymentResponseModule)
	},
	{
		path: 'create-payment-response/:token',
		loadChildren: () => import('./create-payment-response/create-payment-response.module').then(m => m.CreatePaymentResponseModule)
	},
	{
		path: 'not-found',
		loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
	},
	{
		path: 'manage-accounts/:token',
		loadChildren: () => import('./manage-accounts/manage-accounts.module').then(m => m.ManageAccountsModule)
	},
	{
		path: '**',
		redirectTo: 'not-found'
		// loadChildren: "./not-found/not-found.module#NotFoundModule"
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
